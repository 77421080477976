<script setup lang="ts">
import { type IBookBase } from "~/types/book";
import { shorteningNumber } from "~/utils/numbers";
import { useDevice } from "#imports";

interface IProps {
  book: IBookBase,
  badge?: string|number|null,
}

const config = useRuntimeConfig();
const { isMobile } = useDevice();

const props = withDefaults(defineProps<IProps>(), {
  badge: null,
});

const genre = computed(() => props.book.genres
    ? props.book.genres[0]
    : null
);

const isUploadedByUser = computed(() => typeof props.book.uploaded_by === "string");
</script>

<template>
  <div class="book-detailed-card flex items-stretch gap-3 p-4 border border-[#EFF2F5] dark:border-zinc-700 rounded max-lg:border-0 max-lg:p-0">
    <div class="flex-none overflow-hidden relative">
      <nuxt-link :to="isUploadedByUser ? `/book/${book.slug}` : `/books/${book.slug}`" class="block rounded-lg overflow-hidden  w-[160px] max-md:w-[116px]">
        <BookCover
            :path="book.cover_path"
            :url="book.cover"
            :alt="`Book cover of “${book.title}“ by ${book.author}`"
            loading="lazy"
            :quality="80"
            :width="isMobile ? 124 : 160"
            :height="isMobile ? 173 : 217"
            format="webp"
        />
      </nuxt-link>
      <div v-if="badge !== ''" class="badge">{{ badge }}</div>
    </div>
    <div class="book-details flex flex-col gap-2 justify-items-start items-stretch">
      <nuxt-link :to="isUploadedByUser ? `/book/${book.slug}` : `/books/${book.slug}`" class="font-semibold text-lg leading-5">
        <h3>{{ book.title }}</h3>
      </nuxt-link>

      <nuxt-link :to="`/genres/${book.genres[0].slug}-novels`" v-if="book?.genres && book.genres.length > 0" class="text-violet-800 dark:text-violet-500">
        {{ book.genres[0].name }}
      </nuxt-link>

      <div class="grow text-sm leading-5">
        <nuxt-link :to="`/books/${book.slug}`" class="!text-gray-600 dark:!text-gray-400 line-clamp-4 max-md:line-clamp-3">
          <span v-html="book.description"></span>
        </nuxt-link>
      </div>

      <div class="flex justify-between text-gray-500 text-sm">
        <div>{{ shorteningNumber(book?.views) }} views</div>
        <div>{{ book.is_completed ? 'Completed' : 'Ongoing' }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.badge {
  position: absolute;
  top: 10px;
  left: -20px;
  transform: rotate(-45deg);
  background: #F18F01;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: 80px;
  text-align: center;
}
</style>